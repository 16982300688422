import { store } from '@/store/store';
import { ticket } from '@/shared/dtos/ticket';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'ticketModule',
    store,
    dynamic: true
})
class ticketModule extends VuexModule {
    public tickets: ticket[] = [];
    public ticket: ticket = new ticket();

    @Action({ commit: 'onGettickets' })
    public async gettickets() {
        return await ssmHttpService.get(API.ticket);
    }

    @Action({ commit: 'onGetticket' })
    public async getticket(id: any) {
        return await ssmHttpService.get(API.ticket + '/' + id);
    }

    @Action
    public async guardarticket(ticket: ticket) {
       return await ssmHttpService.post(API.ticket, ticket.toJson());
    }

    @Action
    public async modificarticket(ticket: ticket) {
        return await ssmHttpService.put(API.ticket + '/' + ticket.id, ticket);
    }

    @Action
    public async eliminarticket(ticket: ticket) {
       return await ssmHttpService.delete(API.ticket + '/' + ticket.id, null, false);
    }

    @Mutation
    public onGettickets(res: ticket[]) {
        this.tickets = res ? res.map((x) => new ticket(x)) : []
    }

    @Mutation
    public onGetticket(res: ticket) {
        this.ticket = new ticket(res);
    }

}
export default getModule(ticketModule);